import React from 'react';
import { Link } from 'gatsby';
import { globalHistory as history } from '@reach/router'

const Footer = () => {

    const { location } = history;

    const contactCue = () => (

        <div className="contact-cue">
            <h2>Start Improving Your Business Today!</h2>
            <Link to="/contact" className="contact-btn">Contact Us</Link>
        </div>

    )


    return ( 

        <footer>
            {location.pathname !== '/contact' ? contactCue() : ''}
            <div className="newsletter-subscribe">
                <a className="btn" href="http://eepurl.com/bsoQeX" target="_blank" rel="noopener noreferrer" >Signup for our Newsletter</a>
            </div>
            <div className="inner">
                
                <p className="copyright">&copy; Copyright Experience On Tap {(new Date().getFullYear())}</p>
            </div>
        </footer> 

    )
}

export default Footer;