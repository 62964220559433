import { Link } from "gatsby";
import {useIntersector} from './customhooks'
import PropTypes from "prop-types";
import React, {useState, useRef} from "react";
import { useMediaQuery } from 'react-responsive';
//not sure if this is the way to go
import { globalHistory as history } from '@reach/router'

const Header = () => {

    const [isIntersecting, setIsIntersecting] = useState(true);
    const eInterTarget = useRef();
    const { location } = history

    const mobileMenu = useMediaQuery({ query: '(max-width: 50em)' })
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useIntersector({
        eInterTarget, 
        rootMargin: "50px",
        onIntersect: ([{isIntersecting}]) => setIsIntersecting(isIntersecting)
    });
   
    return (
        <>
       
        <header className={`${!isIntersecting ? "scroll" : 'no-scroll'} ${mobileMenuOpen ? "menu-open" : ""}`}>
           {location.pathname === '/' ? 
                    <Link to='' className="covid-notice">
                    
                </Link> : ''
        }
            
            <div className="inner">
                <Link className="logo" to="/"></Link>

            {mobileMenu && (
                <>
               
                <button className="menu-button" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}><span></span></button>

                </>
            )}
            <nav> 

                <ul>
                    <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to='/about'>About</Link>
                    </li>
                    <li>
                        <Link to='/how-we-help'>How We Help</Link>
                    </li>
                    <li>
                        <Link to='/tidy-software'>Tidy Software</Link>
                    </li>
                    <li>
                        <Link to='/our-partners'>Our Partners</Link>
                    </li>
                    <li>
                        <Link to='/contact'>Contact</Link>
                    </li>

                </ul>
            </nav>

            </div>
        </header>

        {mobileMenu && (<button className={`mobile-exit ${mobileMenuOpen ? "menu-open" : ""}`} onClick={() => setMobileMenuOpen(false)}></button>)}

        <div className="scroll-intersect" ref={eInterTarget}></div>
        </>
    )

}

Header.propTypes = {
    siteTitle: PropTypes.string,
}
  
Header.defaultProps = {
    siteTitle: ``,
}


export default Header;